import React, { Suspense } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch
} from 'react-router-dom';

import Users from './user/pages/Users';
// import NewPlace from './places/pages/NewPlace';
// import UserPlaces from './places/pages/UserPlaces';
// import UpdatePlace from './places/pages/UpdatePlace';
// import Auth from './user/pages/Auth';
import MainNavigation from './shared/components/Navigation/MainNavigation';
import LoadingSpinner from './shared/components/UIElements/LoadingSpinner';
// import UserManagers from './places/pages/UserManagers';
// import NewManager from './places/pages/NewManager';

// import { Drizzle } from '@drizzle/store';
// import { drizzleReactHooks } from "@drizzle/react-plugin";
// import drizzleOptions from "./TokenProduct/drizzleOptions";
// import LoadingContainer from './TokenProduct/LoadingContainer';
import SponsorProduct from './TokenProduct/SponsorProduct';
import CreateTokens from './TokenProduct/CreateTokens';
import MyNFTs from './TokenProduct/MyNFTs.js';
import ManageTokens from './TokenProduct/ManageTokens.js';
import MarkplaceFunction from './TokenProduct/MarkplaceFunction.js';
import Fantasy from './TokenProduct/Fantasy';
import NFTItems from './TokenProduct/NFTItems';


// const Users = React.lazy(() => import('./user/pages/Users'));
// const drizzle = new Drizzle(drizzleOptions);
// const { DrizzleProvider } = drizzleReactHooks;



const App = ({web3, accounts, contracts, admin}) => {


  let routes;


};

export default App;
